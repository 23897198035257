/* --- CONFIGURATIONS ------------------------------------------------------- */
/**
 * ATTENTION!
 * These numbers must be synchronized with src/app/constsants/viewports.js
 */
:root {
  /* --- PAGE AND GRID DIMENSIONS ------------------------------------------- */
  --site-lg-width: 1440px;
  --site-lg-column-spacing: 20px;

  --site-md-width: 768px;
  --site-md-column-spacing: 32px;

  --site-sm-width: 320px;
  --site-sm-column-spacing: 20px;

  /* --- Flex box vars ------------------------------------------------------ */
  --lg-spacing: 40px;
  --md-spacing: 34px;
  --sm-spacing: 20px;

  --site-lg-margin: 40px;
  --site-md-margin: 34px;
  --site-sm-margin: 20px;

  /* --- COLORS ------------------------------------------------------------- */
  --color-satin: #EBEADF;
  --color-black: #252525;
  --color-black-var: #212121;
  --color-black-true: #000;
  --color-black-true-10: rgba(0, 0, 0, 0.1);
  --color-black-true-40: rgba(0, 0, 0, 0.4);
  --color-black-true-50: rgba(0, 0, 0, 0.5);
  --color-black-true-60: rgba(0, 0, 0, 0.6);

  --color-gray: #666;
  --color-gray-light: #999;
  --color-gray-lighter: #CCC;
  --color-gray-lightest: #EEE2D6;
  --color-gray-dark: #333;
  --color-gray-dark-smalltext: #575757;
  --color-gray-darkish: #4A4A4A;
  --color-gray-dark-20: rgba(51, 51, 51, 0.2);
  --color-gray-dark-50: rgba(51, 51, 51, 0.5);

  --color-gray-cart: #BCBBB4;
  --color-gray-btn-cart: #EAEADF;
  --color-white-btn-cart: #FFFEF1;

  --color-white-true: #FFF;

  --color-white: #FFFEF2;
  --color-white-10: rgba(255, 254, 242, 0.1);
  --color-white-20: rgba(255, 254, 242, 0.2);
  --color-white-50: rgba(255, 254, 242, 0.5);
  --color-white-80: rgba(255, 254, 242, 0.8);
  --color-white-dark: #F6F5E8;
  --color-white-darker: #F0EFE1;
  --color-white-lighter: #FEFFF8;

  --color-off-white-lighter: #E0DFD3;
  --color-off-white-light: #E5E4D8;
  --color-off-white-dark: #EBEADE;
  --color-off-white-darker: #DFDFD4;

  --color-alabaster: #EBEADE;
  --color-blue-focus: #5E9ED7;
  --color-green-success: #008000;
  --color-red-error: #F00;
  --color-red: #EA6956;
  --color-tan: #D5D5CC;

  --color-transparent: rgba(0, 0, 0, 0.001);

  --color-special-opening-hours: #945C26;

  /* --- COMPONENTS --------------------------------------------------------- */
  --cart-height-closed: 5px;

  --menu-border-color: #DFDFD4;
  --menu-height: 80px;

  --logo-offset-sm: 60px;
  --logo-offset-md: 95px;
  --logo-offset-lg: 120px;

  --panel-product-form-height: 130px;

  --cp-body-row-height-md: 370px;
  --cp-body-row-height-lg: 530px;

  /* --- AUTOCOMPLETE ------------------------------------------------------- */
  --color-form-autocomplete: #F5ECE4;

  /* --- EASING ------------------------------------------------------------- */
  --ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
  --ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
  --ease-out-bounce: cubic-bezier(0.175, 0.885, 0.32, 1);

}
/* stylelint-disable function-calc-no-invalid */
/* stylelint-enable function-calc-no-invalid */
/* stylelint-disable function-calc-no-invalid */
/* stylelint-enable function-calc-no-invalid */
@font-face {
  font-family: 'Portrait Web';
  src: url('./styles/fonts/Portrait/Portrait-Regular-Web.woff2') format('woff2'),
    url('./styles/fonts/Portrait/Portrait-Regular-Web.woff') format('woff'),
    url('./styles/fonts/Portrait/Portrait-Regular-Web.eot');
}
@font-face {
  font-family: 'Suisse Regular';
  src: url('./styles/fonts/Suisse/SuisseIntl-Regular-WebS.woff2') format('woff2'),
    url('./styles/fonts/Suisse/SuisseIntl-Regular-WebS.woff') format('woff'),
    url('./styles/fonts/Suisse/SuisseIntl-Regular-WebS.ttf') format('truetype');
}
@font-face {
  font-family: 'Suisse Medium';
  src: url('./styles/fonts/Suisse/SuisseIntl-Medium-WebS.woff2') format('woff2'),
    url('./styles/fonts/Suisse/SuisseIntl-Medium-WebS.woff') format('woff'),
    url('./styles/fonts/Suisse/SuisseIntl-Medium-WebS.ttf') format('truetype');
}
@font-face {
  font-family: 'Suisse SemiBold';
  src: url('./styles/fonts/Suisse/SuisseIntl-SemiBold-WebS.woff2') format('woff2'),
    url('./styles/fonts/Suisse/SuisseIntl-SemiBold-WebS.woff') format('woff'),
    url('./styles/fonts/Suisse/SuisseIntl-SemiBold-WebS.ttf') format('truetype');
}
@font-face {
  font-family: 'Zapf Humanist';
  src: url('./styles/fonts/Zapf/324B1A_0_0.woff2') format('woff2'),
    url('./styles/fonts/Zapf/324B1A_0_0.woff') format('woff'),
    url('./styles/fonts/Zapf/324B1A_0_0.ttf') format('truetype');
}
@font-face {
  font-family: 'Canela Light';
  src: url('./styles/fonts/Canela/Canela-Light-Web.woff2') format('woff2'),
    url('./styles/fonts/Canela/Canela-Light-Web.woff') format('woff'),
    url('./styles/fonts/Canela/Canela-Light-Web.eot');
}
:root {

  --serif-title-font: "Zapf Humanist", serif
}
html {
  font-size: 0.625em;
}
body {
  font-family: "Suisse Regular", sans-serif;
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-size: inherit;
  font-weight: normal;
  line-height: inherit;
  margin: 0;
  padding: 0;
}
strong,
b {
  font-family: "Suisse Medium", sans-serif;
  font-weight: normal;
}
/* Mixins */
.wysiwyg {
  line-height: 1.7;
}
.wysiwyg h1,
  .wysiwyg h2,
  .wysiwyg h3,
  .wysiwyg h4,
  .wysiwyg h5 {
  font-family: "Suisse Medium", sans-serif;
  }
.wysiwyg > *:not(:last-child) {
    margin-bottom: 2.5rem;
  }
.wysiwyg ul,
  .wysiwyg ol {
    margin-left: 20px;
    margin-left: 20px;
    margin-left: var(--site-sm-margin);
  }
.wysiwyg a,
    .wysiwyg a:link {
      color: #4A4A4A;
      color: #4A4A4A;
      color: var(--color-gray-darkish);
      font-weight: 400;
      text-decoration: underline;
    }
.wysiwyg a:hover {
      color: #252525;
      color: #252525;
      color: var(--color-black);
    }
@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes fade-in-out {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@keyframes fade-out-in {
  0% {
    opacity: 1;
  }

  40% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes fade-in--text {
  from {
    color: transparent;
  }

  to {
    color: #FFFEF2;
    color: #FFFEF2;
    color: var(--color-white);
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
@keyframes fade-out--delayed {
  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@keyframes fade-in--delayed {
  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes scale-down {
  from {
    transform: scale(1.02, 1.02);
  }

  to {
    transform: scale(1, 1);
  }
}
@keyframes slide-in-left {
  from {
    transform: translate(15px, 0);
  }

  to {
    transform: translate(0, 0);
  }
}
@keyframes slide-in-right {
  from {
    transform: translate(-15px, 0);
  }

  to {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; /* Fixes text rendering bug after the animation runs. Adding this to the element directly doesn't work */
    transform: translate(0, 0);
  }
}
@keyframes slide-left--20 {
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(-20%, 0);
  }
}
@keyframes slide-left--100 {
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(-100%, 0);
  }
}
@keyframes slide-left--200 {
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(-200%, 0);
  }
}
@keyframes slide-left--300 {
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(-300%, 0);
  }
}
@keyframes slide-out-right {
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(15px, 0);
  }
}
@keyframes slide-right--100 {
  from {
    transform: translate(-100%, 0);
  }

  to {
    transform: translate(0, 0);
  }
}
@keyframes slide-up--20px {
  from {
    transform: translate(0, 20px);
  }

  to {
    transform: translate(0, 0);
  }
}
@keyframes slide-down--100 {
  from {
    transform: translate(0, -100%);
  }

  to {
    transform: translate(0, 0);
  }
}
@keyframes move-down--20 {
  from {
    transform: translateY(-20px);
  }

  to {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transform: translateY(0);
  }
}
@keyframes move-left--30 {
  from {
    transform: translateX(30px);
  }

  to {
    transform: translateX(0);
  }
}
@keyframes scale-up--80 {
  from {
    transform: scale(0.8);
  }

  to {
    transform: scale(1);
  }
}
@keyframes scale-up--delayed-80 {
  50% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1);
  }
}
@keyframes scale-down--80 {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0.8);
  }
}
@keyframes slide-up--100 {
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(0, -100%);
  }
}
@keyframes slide-up--0 {
  from {
    transform: translate(0, 100%);
  }

  to {
    transform: translate(0, 0);
  }
}
@keyframes slide-down--0 {
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(0, 100%);
  }
}
@keyframes filter-enter-ml {
  from {
    max-height: 0;
  }

  to {
    max-height: 50vh;
  }
}
@keyframes filter-exit-ml {
  from {
    max-height: 50vh;
  }

  to {
    max-height: 0;
  }
}
@keyframes full-page-filter-enter-ml {
  from {
    max-height: 0;
  }

  to {
    max-height: 100vh;
  }
}
@keyframes full-page-filter-exit-ml {
  from {
    max-height: 100vh;
  }

  to {
    max-height: 0;
  }
}
@keyframes subcat-product-details-enter-md {
  from {
    max-height: 0;
    padding: 0 34px;
    padding: 0 34px;
    padding: 0 var(--md-spacing);
  }

  to {
    max-height: 170px;
    padding: 0 34px 30px;
    padding: 0 34px 30px;
    padding: 0 var(--md-spacing) 30px;
  }
}
@keyframes subcat-product-details-enter-lg {
  from {
    max-height: 0;
    padding: 0 40px;
    padding: 0 40px;
    padding: 0 var(--lg-spacing);
  }

  to {
    max-height: 170px;
    padding: 0 40px 30px;
    padding: 0 40px 30px;
    padding: 0 var(--lg-spacing) 30px;
  }
}
@keyframes subcat-product-enter {
  from {
    max-height: 0;
  }

  to {
    max-height: 550px;
  }
}
@keyframes cat-product-enter-md {
  from {
    max-height: 0;
  }

  to {
    max-height: 370px;
  }
}
@keyframes cat-product-exit-md {
  from {
    max-height: 370px;
  }

  to {
    max-height: 0;
  }
}
@keyframes cat-product-enter-lg {
  from {
    max-height: 0;
  }

  to {
    max-height: 530px;
  }
}
@keyframes cat-product-exit-lg {
  from {
    max-height: 530px;
  }

  to {
    max-height: 0;
  }
}
@keyframes pdp-add-label {
  25% {
    opacity: 0;
    transform: translateY(-100%);
  }

  75% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes pdp-add-label--action {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  25%,
  75% {
    opacity: 1;
    transform: translateY(-100%);
  }

  100% {
    opacity: 0;
    transform: translateY(-200%);
  }
}
@keyframes btn-label-animation {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}
@keyframes inline-error-animate-in {
  from {
    max-height: 0;
    opacity: 0;
  }

  to {
    max-height: 40px;
    opacity: 1;
  }
}
@keyframes slide-bottom-in--100 {
  from {
    transform: translate(0, 100%);
  }

  to {
    transform: translate(0, 0);
  }
}
@keyframes slide-bottom-out--100 {
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(0, 100%);
  }
}
@keyframes skin-types-button-on {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes skin-types-button-off {
  from {
    opacity: 1;
    transform: none;
  }

  to {
    opacity: 0;
    transform: translateY(100%);
  }
}
:root {
  --theme-alabaster-1: #FFFEF2;
  --theme-alabaster-2: #F6F5E8;
  --theme-alabaster-3: #EBEADE;

  --theme-body-1: #EBE2D5;
  --theme-body-2: #DFD5C8;
  --theme-body-3: #DACEC1;

  --theme-resolute-1: #F0F1F1;
  --theme-resolute-2: #E3E5E5;
  --theme-resolute-3: #D7DBDB;

  --theme-reverence-1: #F1F5F7;
  --theme-reverence-2: #E6ECEF;
  --theme-reverence-3: #DCE5E8;

  --theme-rejuvenate-1: #FBF8F5;
  --theme-rejuvenate-2: #F7F1EC;
  --theme-rejuvenate-3: #F5ECE4;

  --theme-resurrection-1: #F6F2EF;
  --theme-resurrection-2: #F0EBE4;
  --theme-resurrection-3: #EBE3DA;

  --theme-rind-1: #FCF1ED;
  --theme-rind-2: #F8E4DD;
  --theme-rind-3: #F5DCD2;

  --theme-geranium-1: #F6F9F4;
  --theme-geranium-2: #F0F4EB;
  --theme-geranium-3: #E9F0E3;

  --theme-redemption-1: #F1F1F0;
  --theme-redemption-2: #E5E5E4;
  --theme-redemption-3: #D9D9D8;

  --theme-hair-1: #DED8D4;
  --theme-hair-2: #D9D0CB;
  --theme-hair-3: #D5CCC6;

  --theme-fragrance-1: #D6DADD;
  --theme-fragrance-2: #B6BCC0;
  --theme-fragrance-3: #ADB3B8;
}
/* --- GLOBALS -------------------------------------------------------------- */
* {
  box-sizing: inherit;
}
html {
  box-sizing: border-box;
  color: #333;
  color: #333;
  color: var(--color-gray-dark);
  height: 100%;
}
body {
  background-color: #FFFEF2;
  background-color: #FFFEF2;
  background-color: var(--theme-alabaster-1);
  margin: 0;
}
/** https://gist.github.com/ffoodd/000b59f431e3e64e4ce1a24d5bb36034 */
.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
  -webkit-clip-path: inset(50%) !important;
          clip-path: inset(50%) !important; /* 2 */
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important; /* 3 */
  width: 1px !important;
}
body.has-noScrolling {
  overflow: hidden
}
@media (max-width: 1024px) {
body.has-noScrolling {
    position: fixed;
    width: 100%
}
  }
a {
  color: inherit;
  text-decoration: none;
}
input[type="checkbox"],
input[type="radio"],
button {
  cursor: pointer;
}
button,
input,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
}
button {
  background: transparent;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
}
/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/
.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}
a:focus,
button:focus,
input[type="checkbox"]:focus ~ .FormCheckbox-focusElement,
input[type="radio"]:focus ~ .FormRadio-focusElement,
[tabindex]:focus {

  /* body:not(.has-tabbed) & {
    outline: none;
  } */
}
.js-focus-visible a:focus.focus-visible, .js-focus-visible button:focus.focus-visible, .js-focus-visible input[type="checkbox"]:focus ~ .FormCheckbox-focusElement.focus-visible, .js-focus-visible input[type="radio"]:focus ~ .FormRadio-focusElement.focus-visible, .js-focus-visible [tabindex]:focus.focus-visible {
    outline: 1px solid rgba(51, 51, 51, 0.2);
    outline: 1px solid rgba(51, 51, 51, 0.2);
    outline: 1px solid var(--color-gray-dark-20);
    outline-offset: 5px;
    transition: outline-offset 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: outline-offset 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: outline-offset 200ms var(--ease-in-out-cubic)

    /**
    * Some elements are inside containers that prevent the outline from being
    * visible. Since most of them are product images that already have enough
    * spacing, we're using a negative outline offset so it renders inside the
    * item.
    **/
  }
.js-focus-visible a:focus.focus-visible.has-overflowHidden, .js-focus-visible button:focus.focus-visible.has-overflowHidden, .js-focus-visible input[type="checkbox"]:focus ~ .FormCheckbox-focusElement.focus-visible.has-overflowHidden, .js-focus-visible input[type="radio"]:focus ~ .FormRadio-focusElement.focus-visible.has-overflowHidden, .js-focus-visible [tabindex]:focus.focus-visible.has-overflowHidden {
      outline-offset: -5px;
    }
body.has-tabbed .has-lightText a:focus, body.has-tabbed .has-lightText button:focus, body.has-tabbed .has-lightText input[type="checkbox"]:focus ~ .FormCheckbox-focusElement, body.has-tabbed .has-lightText input[type="radio"]:focus ~ .FormRadio-focusElement, body.has-tabbed .has-lightText [tabindex]:focus {
    outline-color: rgba(255, 254, 242, 0.5);
    outline-color: rgba(255, 254, 242, 0.5);
    outline-color: var(--color-white-50);
  }
ul {
  list-style-type: disc;
  padding: 0;
}
ol {
  padding: 0;
}
img {
  max-width: 100%;
}
@media (min-width: 640px) and (max-width: 1024px) {
.container-md-only {
    width: calc((100% - 34px * 2) * 4 / 6);
    width: calc((100% - 34px * 2) * 4 / 6);
    width: calc((100% - var(--md-spacing) * 2) * 4 / 6);
    margin-left: auto;
    margin-right: auto
}
  }
/* --- COMPONENTS: COMMON --------------------------------------------------- */
.Link {
  font-family: "Suisse Medium", sans-serif
}
.Link.Link--isBtn,
  .Link.Link--hasIcon {
    display: inline-block;
  }
.Link.Link--isBtn {
  font-family: "Suisse Medium", sans-serif;
  font-size: 1.4rem;
  line-height: 1.1;
    border: 1px solid rgba(51, 51, 51, 0.2);
    border: 1px solid rgba(51, 51, 51, 0.2);
    border: 1px solid var(--color-gray-dark-20);
    color: #333;
    color: #333;
    color: var(--color-gray-dark);
    height: 60px;
    min-width: 210px;
    padding: 0 23px;
    transition: color 200ms cubic-bezier(0.645, 0.045, 0.355, 1),
      background-color 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: color 200ms cubic-bezier(0.645, 0.045, 0.355, 1),
      background-color 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: color 200ms var(--ease-in-out-cubic),
      background-color 200ms var(--ease-in-out-cubic);
  }
body:not(.has-touch) .Link.Link--isBtn:not(.is-disabled):focus,
    body:not(.has-touch) .Link.Link--isBtn:not(.is-disabled):hover {
      background-color: #333;
      background-color: #333;
      background-color: var(--color-gray-dark);
      color: #FFFEF2;
      color: #FFFEF2;
      color: var(--color-white);
    }
.Link.Link--isBtn:active {
      background-color: #000;
      background-color: #000;
      background-color: var(--color-black-true);
    }
.Link.Link--isBtn.is-disabled {
      background-color: #FFFEF2;
      background-color: #FFFEF2;
      background-color: var(--color-white);
      border-color: #D5D5CC;
      border-color: #D5D5CC;
      border-color: var(--color-tan);
      cursor: default;
    }
.Link.Link--isBtnFilled {
    background-color: #333;
    background-color: #333;
    background-color: var(--color-gray-dark);
    color: #FFFEF2;
    color: #FFFEF2;
    color: var(--color-white);
  }
body:not(.has-touch) .Link.Link--isBtnFilled:not(.is-disabled):focus,
    body:not(.has-touch) .Link.Link--isBtnFilled:not(.is-disabled):hover {
      background-color: #000;
      background-color: #000;
      background-color: var(--color-black-true);
    }
.Link.Link--isBtnLight {
  font-family: "Suisse Medium", sans-serif;
  font-size: 1.4rem;
  line-height: 1.1;
    border: 1px solid #FFFEF2;
    border: 1px solid #FFFEF2;
    border: 1px solid var(--color-white);
    color: #FFFEF2;
    color: #FFFEF2;
    color: var(--color-white);
    height: 60px;
    min-width: 210px;
    padding: 21px 23px;
    transition: color 200ms cubic-bezier(0.645, 0.045, 0.355, 1),
      background-color 200ms cubic-bezier(0.645, 0.045, 0.355, 1),
      border-color 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: color 200ms cubic-bezier(0.645, 0.045, 0.355, 1),
      background-color 200ms cubic-bezier(0.645, 0.045, 0.355, 1),
      border-color 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: color 200ms var(--ease-in-out-cubic),
      background-color 200ms var(--ease-in-out-cubic),
      border-color 200ms var(--ease-in-out-cubic);
  }
body:not(.has-touch) .Link.Link--isBtnLight:not(.is-disabled):focus,
    body:not(.has-touch) .Link.Link--isBtnLight:not(.is-disabled):hover {
      background-color: #EBEADE;
      background-color: #EBEADE;
      background-color: var(--color-alabaster);
      border-color: #EBEADE;
      border-color: #EBEADE;
      border-color: var(--color-alabaster);
      color: #333;
      color: #333;
      color: var(--color-gray-dark);
    }
.Link.Link--isBtnLight:active {
      background-color: #000;
      background-color: #000;
      background-color: var(--color-black-true);
    }
.Link.Link--isBtnLight.is-disabled {
      background-color: #999;
      background-color: #999;
      background-color: var(--color-gray-light);
      border-color: #999;
      border-color: #999;
      border-color: var(--color-gray-light);
      color: #D5D5CC;
      color: #D5D5CC;
      color: var(--color-tan);
      cursor: default;
    }
.Link.Link--isBtn .Link-content,
  .Link.Link--isBtnLight .Link-content,
  .Link.Link--hasIcon .Link-content {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;
  }
.Link.Link--isBtn.Link--hasIcon .Link-content,
  .Link.Link--isBtnLight.Link--hasIcon .Link-content {
    justify-content: space-between;
    padding-right: 46px;
  }
.Link-icon {
  fill: #333;
  fill: #333;
  fill: var(--color-gray-dark);
  height: 12px;
  margin-left: 8px;
  width: 12px;
}
.Link.Link--isBtnFilled .Link-icon,
  .Link.Link--isBtnLight .Link-icon {
    fill: #FFFEF2;
    fill: #FFFEF2;
    fill: var(--color-white);
  }
.Link.Link--isBtn .Link-icon {
    position: absolute;
    right: 0;
    top: calc(50% - 6px);
  }
.Link:not(.Link--isBtn) .Link-icon {
    display: inline-block;
  }
.Link.Link--isBtnFilled .Link-icon,
  .Link.Link--isBtn .Link-icon,
  .Link.Link--isBtnLight .Link-icon {
    margin-left: 23px;
    transition: fill 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: fill 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: fill 200ms var(--ease-in-out-cubic);
  }
body:not(.has-touch) .Link.Link--isBtnFilled:not(.is-disabled):focus .Link-icon,
  body:not(.has-touch) .Link.Link--isBtnFilled:not(.is-disabled):hover .Link-icon,
  body:not(.has-touch) .Link.Link--isBtn:not(.is-disabled):focus .Link-icon,
  body:not(.has-touch) .Link.Link--isBtn:not(.is-disabled):hover .Link-icon {
    fill: #FFFEF2;
    fill: #FFFEF2;
    fill: var(--color-white);
  }
body:not(.has-touch) .Link.Link--isBtnLight:not(.is-disabled):focus .Link-icon,
  body:not(.has-touch) .Link.Link--isBtnLight:not(.is-disabled):hover .Link-icon {
    fill: #333;
    fill: #333;
    fill: var(--color-gray-dark);
  }
.Link.Link--isBtn.is-disabled .Link-icon,
  .Link.Link--isBtnLight.is-disabled .Link-icon {
    fill: #D5D5CC;
    fill: #D5D5CC;
    fill: var(--color-tan);
  }
.ImgCaption-wrapper {
  padding: 0 20px;
  padding: 0 20px;
  padding: 0 var(--sm-spacing);
}
.MCSquareImage-captionWrapper .ImgCaption-wrapper {
    padding: 0;
  }
@media (min-width: 640px) {
.ImgCaption-wrapper {
    padding: 0
}
  }
.ImgCaption-captionAuthor, .ImgSource-headline, .ImgSource-source, .ImgCaption-headline {
  font-family: 'Suisse Medium', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8;
}
.ImgCaption-caption {
  color: #666;
  color: #666;
  color: var(--color-gray);
  font-family: 'Suisse Regular', sans-serif;
  font-size: 1.4rem;
  line-height: 1.8;
}
.ImgCaption-captionAuthor {
  padding-left: 8px;
}
.ImgSource-headline, .ImgCaption-captionAuthor, .ImgCaption-headline {
  color: #212121;
  color: #212121;
  color: var(--color-black-var);
  padding-bottom: 8px;
}
.ImgSource-source {
  color: #666;
  color: #666;
  color: var(--color-gray);
  text-decoration: underline;
}
/* --- COMPONENTS -------------------------------------------------------------- */
@media (max-width: 639px) {
.Nav {
    padding-left: 20px;
    padding-left: 20px;
    padding-left: var(--site-sm-margin);
    padding-right: 20px;
    padding-right: 20px;
    padding-right: var(--site-sm-margin)
}
  }
@media (max-width: 1024px) {
.Nav {
    background-color: #EBEADF;
    background-color: #EBEADF;
    background-color: var(--color-satin)
}
  }
@media (min-width: 1025px) {
.Nav {
    padding-left: 40px;
    padding-left: 40px;
    padding-left: var(--site-lg-margin);
    padding-right: 40px;
    padding-right: 40px;
    padding-right: var(--site-lg-margin);
    margin-bottom: 140px
}
  }
.Nav--ul {
    display: flex;
    align-items: center;
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;
    height: 60px
  }
@media (min-width: 1025px) {

  .Nav--ul {
      height: 70px
  }
    }
.Nav--li {
    margin-right: 34px;
    margin-right: 34px;
    margin-right: var(--site-md-margin)
  }
@media (min-width: 640px) {

  .Nav--li {
      margin-right: 40px;
      margin-right: 40px;
      margin-right: var(--site-lg-margin)
  }
    }
.Nav--link {
  font-family: "Suisse Medium", sans-serif;
    font-size: 1.4rem;
    line-height: 1.7
  }
@media (min-width: 640px) {
      .Nav--link::after {
        border-bottom: solid 1px #333;
        border-bottom: solid 1px #333;
        border-bottom: solid 1px var(--color-gray-dark);
        content: '';
        display: block;
        margin-top: -3px;
        transform: scaleX(0);
        transform-origin: left;
        transition: transform 250ms cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: transform 250ms cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: transform 250ms var(--ease-in-out-cubic);
      }

      .Nav--link:hover::after {
        transform: scaleX(1);
      }
    }
.Hero {
  background-color: #F6F5E8;
  background-color: #F6F5E8;
  background-color: var(--theme-alabaster-2);
  border-top: 6px solid #333;
  border-top: 6px solid #333;
  border-top: 6px solid var(--color-gray-dark);
  margin-bottom: 40px;
  margin-bottom: 40px;
  margin-bottom: var(--site-lg-margin);
  width: 100% /* ie 11 */
}
@media (min-width: 1025px) {
.Hero {
    display: flex;
    min-height: 80vh
}

    .Hero::after {
      content: "";
      min-height: inherit;
      font-size: 0;
    }
  }
.Hero--logo-holder {
    margin-bottom: 2.6rem
  }
@media (max-width: 639px) {

  .Hero--logo-holder {
      margin-bottom: 1.5rem;
      padding-top: 34px;
      padding-top: 34px;
      padding-top: var(--site-md-margin);
      padding-left: 20px;
      padding-left: 20px;
      padding-left: var(--site-sm-margin);
      padding-right: 20px;
      padding-right: 20px;
      padding-right: var(--site-sm-margin)
  }
    }
@media (min-width: 640px) and (max-width: 1024px) {

  .Hero--logo-holder {
      padding-top: 40px;
      padding-top: 40px;
      padding-top: var(--site-lg-margin)
  }
    }
@media (min-width: 1025px) {

  .Hero--logo-holder {
      padding-left: 40px;
      padding-left: 40px;
      padding-left: var(--site-lg-margin);
      padding-right: 40px;
      padding-right: 40px;
      padding-right: var(--site-lg-margin)
  }
    }
.Hero--logo {
    width: 288px;
    height: 31px;
  }
@media (min-width: 1025px) {

  .Hero-col {
      flex: 0 0 50%;
      max-width: 50%
  }
    }
.Hero--image-col {
    position: relative
  }
@media (min-width: 1025px) {

  .Hero--image-col {
      order: 2
  }
    }
@media (min-width: 640px) and (max-width: 1024px) {

  .Hero--content-col {
      padding-bottom: 40px;
      padding-bottom: 40px;
      padding-bottom: var(--site-lg-margin)
  }
    }
@media (min-width: 1025px) {

  .Hero--content-col {
      order: 1
  }
    }
@media (max-width: 639px) {

  .Hero--content-wrapper {
      padding-bottom: 34px;
      padding-bottom: 34px;
      padding-bottom: var(--site-md-margin);
      padding-left: 20px;
      padding-left: 20px;
      padding-left: var(--site-sm-margin);
      padding-right: 20px;
      padding-right: 20px;
      padding-right: var(--site-sm-margin)
  }
    }
@media (min-width: 1025px) {

  .Hero--content-wrapper {
      padding: 0 16% 0 24%
  }
    }
.Hero-picture img {
    display: block;
    width: 100%;
    height: auto
  }
@media (min-width: 1025px) {

  .Hero-picture img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      object-fit: cover
  }
    }
.Footer {
  background-color: #252525;
  background-color: #252525;
  background-color: var(--color-black);
  width: 100%;
  margin-top: 40px;
  margin-top: 40px;
  margin-top: var(--site-lg-margin);
}
@media (min-width: 640px) {
.Footer-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 30px 34px;
    padding: 30px 34px;
    padding: 30px var(--site-md-margin)
}
  }
@media (min-width: 1025px) {
.Footer-wrapper {
    padding: 30px 40px;
    padding: 30px 40px;
    padding: 30px var(--site-lg-margin)
}
  }
@media (min-width: 640px) {
.Footer-menuWrapper {
    display: flex;
    order: 1
}
  }
.Footer-copyright {
  font-size: 1.3rem
}
@media (max-width: 639px) {
.Footer-copyright {
    padding: 28px 20px;
    padding: 28px 20px;
    padding: 28px var(--site-sm-margin)
}
  }
@media (min-width: 640px) {
.Footer-copyright {
    order: 0
}
  }
.Footer-copyright,
.Footer-menuBtn {
  display: flex;
  font-size: 1.5rem;
  line-height: 1.2;
  color: #CCC;
  color: #CCC;
  color: var(--color-gray-lighter);
  text-transform: inherit
}
@media (max-width: 639px) {
.Footer-copyright,
.Footer-menuBtn {
    height: 70px
}
  }
@media (min-width: 640px) {
.Footer-copyright,
.Footer-menuBtn {
    height: auto
}
  }
.Footer-menuBtn {
  text-align: left;
  transition: all 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 300ms var(--ease-out-cubic);
  width: 100%
}
@media (min-width: 640px) {
    .Footer-menuBtn:not(:last-child) {
      margin-right: 50px;
    }
  }
.is-active > .Footer-menuBtn {
    color: #CCC;
    color: #CCC;
    color: var(--color-gray-lighter);
  }
.Footer-menuBtn:focus,
  .Footer-menuBtn:hover {
    color: #FFFEF2;
    color: #FFFEF2;
    color: var(--color-white);
  }
@media (max-width: 639px) {
.Footer-menuBtn {
    border-bottom: 1px solid rgba(255, 254, 242, 0.2);
    border-bottom: 1px solid rgba(255, 254, 242, 0.2);
    border-bottom: 1px solid var(--color-white-20);
    padding: 0 20px;
    padding: 0 20px;
    padding: 0 var(--site-sm-margin)
}
  }
@media (min-width: 640px) {
.Footer-menuBtn {
    align-items: center;
    color: #CCC;
    color: #CCC;
    color: var(--color-gray-lighter);
    display: flex;
    font-size: 1.3rem;
    width: auto
}
  }
.Footer-menuBtnIcon {
  fill: #CCC;
  fill: #CCC;
  fill: var(--color-gray-lighter);
  margin-left: 5px;
  width: 13px;
  height: 13px;
}
@media (min-width: 640px) {
.Footer-openModalBtn,
.Footer-languageBtn {
    border-bottom: 1px solid transparent
}
  }
body:not(.has-touch) .Footer-openModalBtn:focus,
  body:not(.has-touch) .Footer-openModalBtn:hover,
  body:not(.has-touch) .Footer-languageBtn:focus,
  body:not(.has-touch) .Footer-languageBtn:hover {
    border-bottom-color: #CCC;
    border-bottom-color: #CCC;
    border-bottom-color: var(--color-gray-lighter);
  }
body.has-touch .Footer-openModalBtn, body.has-touch .Footer-languageBtn {
    border-bottom-color: #CCC;
    border-bottom-color: #CCC;
    border-bottom-color: var(--color-gray-lighter);
  }
.Footer-openModalIcon {
  fill: #999;
  fill: #999;
  fill: var(--color-gray-light);
  height: 20px;
  position: relative;
  stroke: #999;
  stroke: #999;
  stroke: var(--color-gray-light);
  width: 20px;
}
body:not(.has-touch) .Footer-menuBtn:focus .Footer-openModalIcon,
  body:not(.has-touch) .Footer-menuBtn:hover .Footer-openModalIcon {
    fill: #FFFEF2;
    fill: #FFFEF2;
    fill: var(--color-white);
    stroke: #FFFEF2;
    stroke: #FFFEF2;
    stroke: var(--color-white);
  }
.Footer-openModalIcon .Glyph-addWithCircle--circle {
    stroke-width: 3px;
  }
.Footer-openModalIcon .Glyph-addWithCircle--plus {
    stroke-width: 2px;
  }
@media (min-width: 640px) {
.Footer-openModalIcon {
    display: none
}
  }
.Footer-menuBtnWrapper {
  align-items: center;
  display: flex;
  white-space: nowrap;
  width: 100%
}
@media (min-width: 640px) {
.Footer-menuBtnWrapper {
    justify-content: space-between
}
  }
@media (max-width: 639px) {
.Footer-onlyOption {
    display: none
}
  }
@media (min-width: 640px) {
.Footer-languageBtn {
    list-style: none;
    margin: 0;
    padding: 0;
    align-items: center;
    display: flex;
    margin-left: 2rem
}
  }
.MCRow.MCRow--extraVerticalPadding {
    padding-bottom: 60px;
    padding-top: 60px
  }
@media (min-width: 640px) {
.MCRow.MCRow--extraVerticalPadding {
      padding-bottom: 120px;
      padding-top: 120px
  }
    }
.MCFullBleedHeader-wrapper .MCRow {
    position: relative;
    z-index: 1;
  }
@media (min-width: 640px) {
.MCRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}

    .MCRow > section {
      width: calc((100% - 34px * 2) * 4 / 6);
      width: calc((100% - 34px * 2) * 4 / 6);
      width: calc((100% - var(--md-spacing) * 2) * 4 / 6);
    }

    .MCRow > .MCRow-bleed {
      margin-right: 0;
      width: 100%;
    }
  }
@media (min-width: 1025px) {
.MCRow {
    justify-content: flex-end;
    /* opacity: 0; */
    transition: opacity 900ms 100ms cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: opacity 900ms 100ms cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: opacity 900ms 100ms var(--ease-in-out-cubic)
}

    .MCRow.is-visible {
      opacity: 1;
    }
      .MCRow > section:first-child:not(:only-child) {
        margin-left: 40px;
        padding: 0 calc((100% - 40px * 2) / 12) 0 40px;
        padding: 0 calc((100% - 40px * 2) / 12) 0 40px;
        padding: 0 calc((100% - var(--lg-spacing) * 2) / 12) 0 40px;
        width: calc((100% - 80px) * 5 / 12 - 20px);
      }

      .MCRow > section:nth-child(2),
      .MCRow > section:only-child {
        margin-left: 20px;
        margin-right: calc((100% - 40px * 2) * 2 / 12 + 20px);
        margin-right: calc((100% - 40px * 2) * 2 / 12 + 20px);
        margin-right: calc((100% - var(--lg-spacing) * 2) * 2 / 12 + 20px);
        width: calc((100% - 40px) * 5 / 12);
        width: calc((100% - 40px) * 5 / 12);
        width: calc((100% - var(--lg-spacing)) * 5 / 12);
      }

      .MCRow > section.MCRow-bleed {
        margin-left: 0;
        margin-right: 0;
        padding-left: 20px;
        padding-left: 20px;
        padding-left: var(--sm-spacing);
        width: calc((100% - 40px * 2) * 7 / 12 + 60px);
        width: calc((100% - 40px * 2) * 7 / 12 + 60px);
        width: calc((100% - var(--lg-spacing) * 2) * 7 / 12 + 60px);
      }
  }
.MCLeftCopy-wrapper {
  padding: 20px;
  padding: 20px;
  padding: var(--sm-spacing)
}
@media (min-width: 640px) {
.MCLeftCopy-wrapper {
    padding: 34px 0;
    padding: 34px 0;
    padding: var(--md-spacing) 0
}
  }
@media (min-width: 1025px) {
.MCLeftCopy-wrapper {
    padding: 40px 0;
    padding: 40px 0;
    padding: var(--lg-spacing) 0
}
  }
.MCLeftCopy-copy {
  margin-bottom: 30px;
}
.MCLeftCopy-title,
.MCLeftCopy-titleAlt {
  margin-bottom: 1.6rem;
  font-family: "Zapf Humanist", serif;
  font-family: "Zapf Humanist"
,
serif;
  font-family: var(--serif-title-font);
  font-size: 2.5rem;
  line-height: 1.4;
}
@media (min-width: 640px) {
.MCLeftCopy-title,
.MCLeftCopy-titleAlt {
    font-size: 3rem;
    line-height: 1.33;
}
  }
.MCLeftCopy-titleAlt {
  font-family: var(--text-normal);
}
.MCLeftCopy-subhead {
  font-family: "Suisse Medium", sans-serif;
  font-size: 1.6rem;
  margin-bottom: 20px;
}
.MCLeftCopy-copyParagraph {
  line-height: 1.7
}
.MCLeftCopy-copyParagraph:not(:last-child) {
    margin-bottom: 10px;
  }
.MCLeftCopy-copyLink {
  font-family: "Suisse Medium", sans-serif;
}
@media (max-width: 639px) {
.MCLeftCopy-cta {
    display: block;
    width: 100%
}
  }
.MCLeftCopy-eyebrow {
  font-size: 1.4rem;
  line-height: 1.4;
  font-family: "Suisse Medium", sans-serif;
  margin-bottom: 20px;
}
@media (min-width: 640px) {
.MCLeftCopy-eyebrow {
    line-height: 1.7;
}
  }
.MCBodyCopy-wrapper {
  padding: 20px;
  padding: 20px;
  padding: var(--sm-spacing)
}
@media (min-width: 640px) {
.MCBodyCopy-wrapper {
    padding: 34px 0;
    padding: 34px 0;
    padding: var(--md-spacing) 0
}
  }
@media (min-width: 1025px) {
.MCBodyCopy-wrapper {
    padding: 40px 0;
    padding: 40px 0;
    padding: var(--lg-spacing) 0
}
  }
.MCBodyCopy-primaryTitle,
.MCBodyCopy-copy,
.MCBodyCopy-cta,
.MCBodyCopy-secondaryTitle,
.MCBodyCopy-address,
.MCBodyCopy-quote {
  margin-bottom: 30px;
}
.MCBodyCopy-primaryTitle,
.MCBodyCopy-secondaryTitle {
  font-size: 2.4rem;
  line-height: 1.25;
}
@media (min-width: 640px) {
.MCBodyCopy-primaryTitle,
.MCBodyCopy-secondaryTitle {
    font-size: 2.6rem;
    line-height: 1.54;
}
  }
@media (min-width: 1025px) {
.MCBodyCopy-primaryTitle,
.MCBodyCopy-secondaryTitle {
    font-size: 3rem;
    line-height: 1.33;
}
  }
.MCBodyCopy-copyParagraph {
  line-height: 1.7;
}
.MCBodyCopy-copyParagraph a,
    .MCBodyCopy-copyParagraph a:link {
      color: #4A4A4A;
      color: #4A4A4A;
      color: var(--color-gray-darkish);
      font-weight: 400;
      text-decoration: underline;
    }
.MCBodyCopy-copyParagraph a:hover {
      color: #252525;
      color: #252525;
      color: var(--color-black);
    }
.MCBodyCopy-copyParagraph:not(:last-child) {
    margin-bottom: 10px;
  }
.MCBodyCopy-content {
  margin-bottom: 3rem;
}
.MCBodyCopy-copyLink {
  font-family: "Suisse Medium", sans-serif;
}
.MCBodyCopy-eyebrow {
  font-size: 1.4rem;
  line-height: 1.4;
  font-family: "Suisse Medium", sans-serif;
  margin-bottom: 20px;
}
@media (min-width: 640px) {
.MCBodyCopy-eyebrow {
    line-height: 1.7
}
  }
.MCFullBleedHeader--hasEyebrow .MCBodyCopy-eyebrow {
    margin-bottom: 16px;
  }
.MCBodyCopy-address {
  display: block;
}
.MCBodyCopy-addressLine {
  font-size: 1.4rem;
  line-height: 1.4;
  color: #666;
  color: #666;
  color: var(--color-gray);
  display: block;
}
@media (min-width: 640px) {
.MCBodyCopy-addressLine {
    line-height: 1.7;
}
  }
.MCBodyCopy-externalIcon {
  fill: #666;
  fill: #666;
  fill: var(--color-gray);
  height: 8px;
  margin-left: 8px;
  width: 8px;
}
.MCBodyCopy-tertiaryTitle {
  font-family: "Suisse Medium", sans-serif;
  font-size: 1.6rem;
  margin-bottom: 20px;
}
.MCBodyCopy-quote {
  font-family: "Zapf Humanist", serif;
  font-size: 2rem;
  line-height: 1.5
}
@media (min-width: 1025px) {
.MCBodyCopy-quote {
    font-size: 2.6rem
}
  }
.MCBodyCopy-externalLink {
  font-size: 1.4rem;
  line-height: 1.4;
  font-family: "Suisse Medium", sans-serif;
  display: block;
}
@media (min-width: 640px) {
.MCBodyCopy-externalLink {
    line-height: 1.7;
}
  }
@media (min-width: 640px) {
.MCSquareImage {
    padding-left: calc(34px + (100% - 34px * 2) / 6);
    padding-left: calc(34px + (100% - 34px * 2) / 6);
    padding-left: calc(var(--md-spacing) + (100% - var(--md-spacing) * 2) / 6)
}
  }
.MCSquareImage-wrapper {
  padding: 20px 0 20px 20px;
  padding: 20px 0 20px 20px;
  padding: var(--sm-spacing) 0 var(--sm-spacing) var(--sm-spacing);
}
.MCSquareImage--noBottomMargin .MCSquareImage-wrapper {
    padding-bottom: 0;
  }
@media (min-width: 640px) {
.MCSquareImage-wrapper {
    padding: 34px 0;
    padding: 34px 0;
    padding: var(--md-spacing) 0
}
  }
@media (min-width: 1025px) {
.MCSquareImage-wrapper {
    padding: 40px 0;
    padding: 40px 0;
    padding: var(--lg-spacing) 0
}
  }
.MCSquareImage-img > img {
    width: 100%;
  }
.MCSquareImage-captionWrapper {
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);
  border-bottom: 1px solid var(--color-gray-dark-20);
  padding: 25px 25px 25px 0
}
@media (min-width: 640px) {
.MCSquareImage-captionWrapper {
    padding: 25px 25px 25px 20px
}
  }
.MCSquareImage-imgWrapper {
  position: relative;
}
.MCSquareImage-link {
  display: block;
}
.MCSquareImage--video {
  height: 100%;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
@media (min-width: 640px) {
.MCAccordion {
    padding-left: calc(100% / 6)
}
  }
.MCAccordion-wrapper {
  padding: 20px 0 20px 20px
}
@media (min-width: 640px) {
.MCAccordion-wrapper {
    padding: 34px 0 34px 20px;
    padding: 34px 0 34px 20px;
    padding: var(--md-spacing) 0 var(--md-spacing) 20px
}
  }
@media (min-width: 1025px) {
.MCAccordion-wrapper {
    padding: 40px 0 40px 0;
    padding: 40px 0 40px 0;
    padding: var(--lg-spacing) 0 var(--lg-spacing) 0
}
  }
.rc-collapse-item {
  padding: 25px 20px 25px 0;
  width: 100%
}
.rc-collapse-item:not(:last-child) {
    border-bottom: 1px solid rgba(51, 51, 51, 0.2);
    border-bottom: 1px solid rgba(51, 51, 51, 0.2);
    border-bottom: 1px solid var(--color-gray-dark-20);
  }
@media (min-width: 640px) {
.rc-collapse-item {
    padding-top: 30px;
    padding-bottom: 30px
}
  }
@media (min-width: 640px) and (max-width: 1024px) {
.rc-collapse-item {
    padding-right: 34px;
    padding-right: 34px;
    padding-right: var(--md-spacing)
}
  }
@media (min-width: 1025px) {
.rc-collapse-item {
    padding-right: calc(15% + 40px);
    padding-right: calc(15% + 40px);
    padding-right: calc(15% + var(--lg-spacing))
}
  }
.rc-collapse-header {
  cursor: pointer;
}
.rc-collapse-content {
  overflow: hidden;
  opacity: 0;
  transition: opacity 500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    height 500ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 500ms cubic-bezier(0.215, 0.61, 0.355, 1),
    height 500ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 500ms var(--ease-out-cubic),
    height 500ms var(--ease-in-out-cubic);
}
.rc-collapse-item-active .rc-collapse-content {
  opacity: 1;
  visibility: visible;
  transition: opacity 800ms cubic-bezier(0.215, 0.61, 0.355, 1) 400ms,
    height 500ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 800ms cubic-bezier(0.215, 0.61, 0.355, 1) 400ms,
    height 500ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 800ms var(--ease-out-cubic) 400ms,
    height 500ms var(--ease-in-out-cubic);
}
.rc-collapse-content-inactive {
  height: 0;
  visibility: hidden;
}
.rc-collapse-content-box {
  padding-top: 20px
}
@media (min-width: 640px) {
.rc-collapse-content-box {
    padding-top: 30px
}
  }
.Accordion {
  color: #333;
  color: #333;
  color: var(--color-gray-dark);
  display: flex;
}
.Accordion--toggleIcon {
    fill: #666;
    fill: #666;
    fill: var(--color-gray);
    height: 15px;
    margin: 7px 0 0 20px;
    transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: transform 300ms var(--ease-out-cubic);
    width: 15px;
  }
.rc-collapse-item-active .Accordion--toggleIcon {
      transform: rotate(180deg);
    }
.Accordion--title {
    font-size: 2rem;
    line-height: 1.4;
  font-family: "Suisse Regular", sans-serif;
  }
@media (min-width: 1025px) {
.Accordion--title {
    font-size: 2.4rem;
    line-height: 1.75
  }
  }
@media (min-width: 1025px) {

  .Accordion--title {
      line-height: 1.4
  }
    }
.Accordion .rc-collapse-extra {
    margin-left: auto;
  }
.MCLinkList {
    list-style: none;
    margin: 0;
    padding: 0;
  }
.MCLinkList-linkItem:not(:last-child) {
    margin-bottom: 20px;
  }
.MCLinkList-link {
    border-bottom: 1px solid transparent;
    transition: border 500ms ease
  }
@media (max-width: 639px) {
.MCLinkList-link {
      display: block;
      width: 100%
  }
    }
@media (min-width: 1025px) {
      .MCLinkList-link:hover:not(.Link--isBtn) {
        border-bottom: 1px solid #333;
        border-bottom: 1px solid #333;
        border-bottom: 1px solid var(--color-gray-dark);
      }
    }
@media (max-width: 639px) {
      .Link--hasIcon.MCLinkList-link .Link-content {
        justify-content: flex-start;
      }
    }
.MCQuote {
  align-items: center;
  display: flex;
  padding: 60px 20px;
  padding: 60px 20px;
  padding: 60px var(--sm-spacing);
  width: 100%
}
@media (min-width: 640px) {
.MCQuote {
    padding: 100px 34px;
    padding: 100px 34px;
    padding: 100px var(--md-spacing)
}
  }
@media (min-width: 1025px) {
.MCQuote {
    padding: 160px 40px;
    padding: 160px 40px;
    padding: 160px var(--lg-spacing);
    transition: opacity 900ms 100ms cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: opacity 900ms 100ms cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: opacity 900ms 100ms var(--ease-in-out-cubic)
}

    .MCQuote.is-visible {
      opacity: 1;
    }
  }
.MCQuote-wrapper {
  margin: 0 auto;
  text-align: center
}
@media (min-width: 640px) {
.MCQuote-wrapper {
    flex-basis: auto;
    /* flex-basis doesn't account for box-sizing:border-box */
    /* stylelint-disable function-calc-no-invalid */
    width: calc(100% * 3 / 6);
    /* stylelint-enable function-calc-no-invalid */
    /* IE is consistently 1px too wide with the standard calc value */
    /* here is a hack so terrible we have to tell the linter to ignore it */
    /* stylelint-enable */
}
    /* stylelint-disable */
    .MCQuote-wrapper _:-ms-input-placeholder,
  :root .MCQuote-wrapper {
    width: calc((100% * 3 / 6) - 1px);
  }
  }
@media (min-width: 1025px) {
.MCQuote-wrapper {
    flex-basis: auto;
    /* flex-basis doesn't account for box-sizing:border-box */
    /* stylelint-disable function-calc-no-invalid */
    width: calc(100% * 6 / 12);
    /* stylelint-enable function-calc-no-invalid */
    /* IE is consistently 1px too wide with the standard calc value */
    /* here is a hack so terrible we have to tell the linter to ignore it */
    /* stylelint-enable */
}
    /* stylelint-disable */
    .MCQuote-wrapper _:-ms-input-placeholder,
  :root .MCQuote-wrapper {
    width: calc((100% * 6 / 12) - 1px);
  }
  }
.MCQuote-paragraph {
  font-family: "Zapf Humanist", serif;
  font-family: "Zapf Humanist"
,
serif;
  font-family: var(--serif-title-font);
  font-size: 2.5rem;
  line-height: 1.4;
  line-height: 1.3;
}
@media (min-width: 640px) {
.MCQuote-paragraph {
    font-size: 3rem;
    line-height: 1.33
}
  }
.MCQuote-paragraph:not(:last-child) {
    margin-bottom: 10px;
  }
.MCQuote-author {
  font-family: 'Suisse Medium', sans-serif;
  font-size: 1.4rem;
  line-height: 2;
  margin-top: 20px;
}